import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

const project = process.env.REACT_APP_INSTANCE;

type ListMenuProps = {
  title: JSX.Element | string;
  icon?: JSX.Element;
  leftIconPath?: string;
  onClick?: () => void;
};
const ListMenu = (props: ListMenuProps): JSX.Element => {
  const { title, icon, onClick, leftIconPath } = props;
  const { t, i18n } = useTranslation();
  let leftIcon;
  if (leftIconPath) leftIcon = require(`../../${leftIconPath}`);

  return (
    <ListMenuContainer isRight={i18n.dir() === "rtl"} onClick={onClick}>
      <LeftContentContainer>
        {leftIconPath && <img src={leftIcon} />}
        {title}
      </LeftContentContainer>
      {icon && <span>{icon}</span>}
    </ListMenuContainer>
  );
};

const LeftContentContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 21px;
    height: 21px;
  }
`;

const ListMenuContainer = styled.div<{ isRight: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 10px 0;
  color: ${({ theme }) => theme.text.listMenu};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: space-between;
  :hover {
    background-color: #333;
  }

  :after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #333;
    position: absolute;
    bottom: 0;
  }

  span {
    text-transform: capitalize;
    padding: 0 10px;
    :last-child {
      right: 0;
    }
    svg {
      transform: ${({ isRight }) =>
        isRight ? "rotateY(180deg)" : "rotateY(0deg)"};
    }
  }
`;
export default ListMenu;
