import { getStorage } from "hooks/useStorage";
import { ReactComponent as FacebookIcon } from "assets/images/social/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/images/social/instagram.svg";
import styled, { css } from "styled-components";

interface SocialMediaProps {
  type: "primary" | "gradient" | "secondary";
  include?: string[];
}

const SocialMedia = (props: SocialMediaProps) => {
  const { type, include } = props;
  const socialLinks = getStorage("APP_OTHER.SOCIAL_LINKS");

  return (
    <SocialListWrapper>
      {(!include || include.includes("FACEBOOK")) && socialLinks.FACEBOOK && (
        <SocialItemWrapper type={type} href={socialLinks.FACEBOOK.LINK}>
          <StyledFacebookIcon type={type} />
        </SocialItemWrapper>
      )}

      {(!include || include.includes("INSTAGRAM")) && socialLinks.INSTAGRAM && (
        <SocialItemWrapper type={type} href={socialLinks.INSTAGRAM.LINK}>
          <StyledInstagramIcon type={type} />
        </SocialItemWrapper>
      )}
    </SocialListWrapper>
  );
};

const SocialListWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SocialItemWrapper = styled.a<{
  type: "primary" | "gradient" | "secondary";
}>`
  width: 42px;
  height: 42px;
  border-radius: 8px;
  cursor: pointer;

  ${({ theme, type }) =>
    type === "secondary" &&
    css`
      background: ${theme.secondary.focused};

      &:hover {
        background: ${theme.primary.main};
      }

      &:active {
        background: ${theme.secondary.focused};
      }
    `}

  ${({ theme, type }) =>
    type === "primary" &&
    css`
      background: ${theme.primary.main};

      &:hover {
        background: ${theme.primary.hover};
      }

      &:active {
        background: ${theme.primary.main};
      }
    `}

  ${({ theme, type }) =>
    type === "gradient" &&
    css`
      background: ${theme.background.gradient};
    `}
`;

const StyledFacebookIcon = styled(FacebookIcon)<{
  type: "primary" | "gradient" | "secondary";
}>`
  ${({ theme, type }) =>
    type === "secondary" &&
    css`
      svg path {
        fill: ${theme.text.white};
      }
    `}

  ${({ theme, type }) =>
    (type === "primary" || type === "gradient") &&
    css`
      path {
        fill: ${theme.text.black};
      }
    `}
`;

const StyledInstagramIcon = styled(InstagramIcon)<{
  type: "primary" | "gradient" | "secondary";
}>`
  ${({ theme, type }) =>
    type === "secondary" &&
    css`
      rect,
      circle {
        stroke: ${theme.text.white};
      }

      circle:last-child {
        fill: ${theme.text.white};
        stroke: transparent;
      }
    `}

  ${({ theme, type }) =>
    (type === "primary" || type === "gradient") &&
    css`
      rect,
      circle {
        stroke: ${theme.text.black};
      }

      circle:last-child {
        fill: ${theme.text.black};
        stroke: transparent;
      }
    `}
`;

export default SocialMedia;
