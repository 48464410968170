import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useGetGameListMutation, useGetTvGamesListMutation} from "../../redux/api/gamesApi";
import {useTypedSelector} from "../../hooks/useTypedRedux";
import ImageIcon from "../../ui/material-kit/ImageIcon";
import InfiniteLoader from "react-window-infinite-loader";
import {FixedSizeGrid, VariableSizeGrid} from "react-window";
import {ReactWindowScroller} from "../../helpers/ReactWindowScroller";
import {IGame} from "../../redux/types/gamesTypes";
import useDimensions from "react-cool-dimensions";
import openGame from "../../helpers/openGame";
import GameCard from "../../ui/GameCard";
import InfiniteScroll from "react-infinite-scroll-component";

interface ITvGamesPageContainer {
    value: {
        game?: string,
        provider?: string
    }
}

const MemoGameCard = React.memo(({item, minHeight}: { item: IGame, minHeight: number }) => {
    return (
        <Game>
            <GameCard isTv={true} game={item} style={{minHeight: minHeight / 2}} isLazy={true}/>
        </Game>
    )
})

const TvGamesPageContainer = (props: ITvGamesPageContainer) => {
    const {game, provider} = props.value;
    const [getTvGamesList, {isLoading}] = useGetTvGamesListMutation();

    const {data, countGames, user} = useTypedSelector((state) => {
        return {
            user: state.userSlice.user,
            data: state.gamesSlice.tvGames.toArray().map(item => item[1]),
            countGames: state.gamesSlice.tvGamesCount
        }
    })

    const [page, setPage] = useState(1)

    useEffect(() => {
        setPage(2);
        getTvGamesList({
            data:
                {
                    type: 'update',
                    page: 1,
                    title: game,
                    provider,
                    size: 20
                }
        })
    }, [game, provider])

    const [hasMoreItems, setHasMoreItems] = useState(true);

    const loadMore = useCallback(() => {
        if (data.length >= countGames) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getTvGamesList({
            data: {
                page,
                title: game,
                provider,
                size: 20
            }
        })
    },[countGames, data.length, game, getTvGamesList, page, provider]);

    const {observe, width} = useDimensions({});
    const columnWidth = useMemo(() => {
        return width / 4
    }, [width])

    const stylesInfinite = useMemo(() => {
        return {
            display: "grid",
            gridTemplateColumns: 'repeat(4, minmax(174px, clamp(174px, 6.718rem + 17.73vw, 448px)))',
            gridGap: '12px',
            height: '100%',
            overflow: 'hidden',
            gridAutoRows: 'min-content'
        }
    }, [])

    const loader = useMemo(() => <></>, [])
    return (
        <div ref={observe} id={`scrollableTvGames`}>
            <InfiniteScroll
                scrollableTarget={`main-wrapper`}
                dataLength={data.length}
                next={loadMore}
                style={stylesInfinite}
                hasMore={hasMoreItems && data.length !== 0}
                loader={loader}
            >
                {data.map((item, index) =>
                    <React.Fragment key={`game-${index}`}>
                        <MemoGameCard item={item} minHeight={columnWidth}/>
                    </React.Fragment>)}
            </InfiniteScroll>
        </div>
    );
};

export default TvGamesPageContainer;

const Game = styled.div`
  box-sizing: border-box;
`