import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {useTranslation} from 'react-i18next';
import {getStorage} from "hooks/useStorage";
import MainCard from "../../ui/cards/MainCard";


type PromotionsCardsProps = {
    isAuth?: boolean,
    fullWidth: number;
}

const projectName = process.env.REACT_APP_INSTANCE;

const MainCards = (props: PromotionsCardsProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const cards: {
        VISIBLE: boolean,
        PATH: string,
        ICON_PATH: string,
    }[] = getStorage("APP_OTHER.MAIN_CARDS");

    const data = Object.values(cards).filter(item => item.VISIBLE)
    return (
        <Wrapper>
            {
                data.map((item, index) => (
                    <React.Fragment key={`promotion-card-${index}-${item.PATH}`}>
                        <MainCard
                            onClick={() => {
                                navigate(item.PATH);
                                window.scrollTo(0, 0);
                            }}
                            icon={item.ICON_PATH}/>
                    </React.Fragment>
                ))
            }
        </Wrapper>
    );
};

export default MainCards;


const Wrapper = styled.div`
  
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-flow: column;
  grid-gap: ${projectName !== 'megawin' ? '17px' : '10px'};
`
