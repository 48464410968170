import React, { useMemo, useState } from "react";
import DrawerContent from "../drawer-new/DrawerContent";
import { ReactComponent as FacebookIcon } from "assets/images/social/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/images/social/instagram.svg";
import Drawer from "ui/material-kit/Drawer";
import styled, { css } from "styled-components";
import { Navigator } from "../drawer-new/Navigator";
import { getStorage } from "../../../hooks/useStorage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { device } from "../../../constants/deviceSizes";
import LanguageController from "../drawer-new/LanguageController";
import IconButton from "ui/material-kit/IconButton";
import SocialMedia from "components/socialMedia";

type CustomDrawerProps = {
  open: boolean;
  setClosed: () => void;
};

const projectName = process.env.REACT_APP_INSTANCE;

const CustomDrawer = (props: CustomDrawerProps): JSX.Element => {
  const { open, setClosed } = props;
  const logo = useMemo(() => {
    const PATH = getStorage("APP_SEO.LOGO_PATH");
    return require(`../../../${PATH}`);
  }, []);

  const socialLinks = getStorage("APP_OTHER.SOCIAL_LINKS");

  const onClose = () => {
    setLanguage(false);
    setClosed();
  };
  const { t, i18n } = useTranslation();
  const [isLanguage, setLanguage] = useState<boolean>(false);
  return (
    <Drawer
      open={open}
      zIndex={999999}
      isRight={i18n.dir() === "rtl"}
      onClose={onClose}
      extraBackground={`top: 0`}
      extraContainer={`margin-top: 0px; height: 100%`}
    >
      <Navigator
        onClose={onClose}
        onBack={isLanguage ? () => setLanguage(false) : undefined}
      />
      <ContainerDrawer>
        {!isLanguage && (
          <Logo
            src={logo}
            style={{
              maxWidth: projectName === "megawin" ? 240 : 311,
              marginBottom: 25,
            }}
            alt="logo-drawer"
          />
        )}
        {isLanguage ? (
          <LanguageController callBack={() => setLanguage(false)} />
        ) : (
          <DrawerContent setLanguage={setLanguage} onClose={onClose} />
        )}
        {!isLanguage && (
          <NavbarItemHallOfFame onClick={onClose}>
            <Link to={"/the-hall-of-fame"}>
              {t("pages.hall_of_fame.title")}
            </Link>
          </NavbarItemHallOfFame>
        )}
        {!isLanguage && projectName === "megawin" && (
          <SocialMedia type="secondary" include={["FACEBOOK", "INSTAGRAM"]} />
        )}
      </ContainerDrawer>
    </Drawer>
  );
};

export default React.memo(CustomDrawer);

const ContainerDrawer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
  background-color: ${({ theme }) => theme.background.sidebar};
  max-width: 440px;
  width: 85vw;
  @media (${device.mobileL}) {
    width: 100vw;
  }
`;

const Logo = styled.img`
  ${() =>
    projectName === "mybet" &&
    css`
      margin-bottom: 24px;
    `}
`;

const NavbarItemHallOfFame = styled.div`
  margin: 25px 0;

  a {
    display: inline-block;
    padding: 13px 16px;
    color: var(--black-000000, #000);
    text-transform: uppercase;
    border-radius: 5px;
    background: ${({ theme }) => theme.background.gradient};
  }
`;

const SocialListWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SocialItemWrapper = styled.a`
  width: 42px;
  height: 42px;
  border-radius: 8px;
  background: ${({ theme }) => theme.secondary.focused};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.secondary.main};
  }

  &:active {
    background: ${({ theme }) => theme.secondary.focused};
  }
`;
