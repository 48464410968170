import { DefaultTheme } from 'styled-components';
import IzibetTheme from './IzibetTheme';
import MegawinTheme from './MegawinTheme';

const projectName = process.env.REACT_APP_INSTANCE ?? 'izibet';

const themes: {
  [key: string]: DefaultTheme
} = {
  izibet: IzibetTheme,
  mybet: IzibetTheme,
  megawin: MegawinTheme,
};

export const getTheme = (): DefaultTheme => {
  return themes[projectName]
}