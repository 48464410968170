import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import settings from "settings";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: Object.keys(settings.SETTINGS.OTHER.LANGUAGES).map(
      (key) => settings.SETTINGS.OTHER.LANGUAGES[key].KEY
    ),
    fallbackLng: "fr",
    react: {
      useSuspense: false,
    },
    debug: true,
    detection: {
      order: ["queryString", "cookie", "navigator"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => {
    console.log("initialize language success", r);
  });

export default i18n;
