import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cashbackImg from "assets/images/cashback.webp";
import {
  useGetLastCashbackMutation,
  useUpdateLastCashbackMutation,
} from "redux/api/userApi";
import { useTypedSelector } from "hooks/useTypedRedux";
import { ReactComponent as ExitIcon } from "assets/images/cross_icon.svg";
import Button from "ui/material-kit/Button";
import LazyImage from "ui/LazyImage";
import styled, { css } from "styled-components";
import { device } from "constants/deviceSizes";
import i18n from "i18n";
import Modal from "ui/Modal";

export const CashbackModal = () => {
  const isRight = i18n.dir() === "rtl";

  const { lastCashback } = useTypedSelector((state) => {
    return {
      lastCashback: state.userSlice.lastCashback,
    };
  });

  const extraModal = `
    z-index: 10002;
    margin-top: 0; 
    height: 100%; 

    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        width: fit-content; 
        max-width: unset;
        height: fit-content; 
        padding: 0;
        border-radius: clamp(0.625rem, 0.568rem + 0.15vw, 0.75rem);

        @media (${device.mobileL}) {
            width: 100%;
            max-height: 100vh;
            height: 100%; 
            border-radius: 0;
        }
    }
  `;

  const [getCashback] = useGetLastCashbackMutation();
  const [updateCashback] = useUpdateLastCashbackMutation();
  const { t } = useTranslation();

  useEffect(() => {
    getCashback();
  }, [getCashback]);

  const changeViewModal = () => {
    if (lastCashback?.id) {
      updateCashback(lastCashback?.id);
    }
  };

  return (
    <Modal open={!!lastCashback} extra={extraModal} onClose={changeViewModal}>
      <ModalContent>
        <ModalCross isRight={isRight} type="button" onClick={changeViewModal}>
          <ExitIcon />
        </ModalCross>
        <ModalImgWrapper>
          <ModalImg src={cashbackImg} alt={""} />
        </ModalImgWrapper>
        <ModalInfo>
          <ModalTop>
            <Text>{t(`common.modals.cashback_modal.you_have_received`)}</Text>
            <ModalValue>
              +{lastCashback?.amount} {lastCashback?.currency}
            </ModalValue>
            <Text>{t(`common.modals.cashback_modal.cashback`)}</Text>
          </ModalTop>
          <ModalBottom>
            <Text>{t(`common.modals.cashback_modal.thank_you`)}</Text>
            <ModalButton id={"cashback-modal-button"} onClick={changeViewModal}>
              {t(`common.modals.cashback_modal.play`)}
            </ModalButton>
          </ModalBottom>
        </ModalInfo>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  overflow: auto;
  position: relative;

  display: flex;
  align-items: center;
  gap: clamp(1rem, 0.459rem + 1.44vw, 2.1875rem);

  padding: clamp(2.1875rem, 2.045rem + 0.38vw, 2.5rem);
  background-color: ${({theme}) => theme.background.main};

  @media (${device.tabletM}) {
    padding-top: 2.75rem;
    flex-direction: column;
  }

  @media (${device.mobileL}) {
    height: 100%;
  }
`;

const ModalCross = styled.button<{ isRight: boolean }>`
  cursor: pointer;
  position: absolute;
  top: clamp(0.625rem, 0.198rem + 1.14vw, 1.5625rem);
  right: clamp(0.625rem, 0.198rem + 1.14vw, 1.5625rem);
  background-color: transparent;

  & > svg {
    path[fill] {
      fill: #a1a1a1;
    }
  }

  ${({ isRight }) =>
    isRight &&
    css`
      right: auto;
      left: clamp(0.625rem, 0.198rem + 1.14vw, 1.5625rem);
    `}
`;

const ModalImgWrapper = styled.div`
  display: flex;
  align-items: center;
  width: clamp(18.125rem, 16.132rem + 5.31vw, 22.5rem);
  aspect-ratio: 1 / 1;

  & > span {
    width: inherit;
  }
`;

const ModalImg = styled(LazyImage)`
  display: block;
  width: inherit;
`;

const ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(1.5rem, 1.358rem + 0.38vw, 1.8125rem);

  padding: clamp(1.25rem, 0.68rem + 1.52vw, 2.5rem);
`;

const ModalTop = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  font-weight: 700;
  font-weight: 400;
  font-size: clamp(1rem, 0.942rem + 0.26vw, 1.25rem);
  text-align: center;
  white-space: pre-line;

  &:nth-of-type(3) {
    font-weight: 700;
    font-size: clamp(1.5rem, 1.327rem + 0.77vw, 2.25rem);
    color: ${({theme}) => theme.primary.main};
  }

  @media (${device.tabletM}) {
    white-space: normal;
  }
`;

const ModalValue = styled.span`
  padding-top: 1rem;

  white-space: nowrap;
  font-weight: 700;
  font-size: clamp(3rem, 2.769rem + 1.03vw, 4rem);
  line-height: clamp(3rem, 2.769rem + 1.03vw, 4rem);
  text-align: center;
  text-transform: uppercase;
  color: ${({theme}) => theme.primary.main};
`;

const ModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
`;

const ModalButton = styled(Button)`
  width: fit-content;
  padding-inline: 1rem;

  font-size: 1.25rem;
`;
