import { DefaultTheme } from "styled-components";
import ITheme from "./ITheme";

const theme: ITheme = {
  hall_of_fame: {
    text_color: "#fff",
    secondary_text_color: "#79531F",
    third_text_color: "#906325",
    main_color: "#4D2608",
    border_color: "#724E1D",
    background_color: "#202020",
    secondary_background_color: "#A1712B",
    third_background_color: "#C89D4B",
    background_hover: "#FFF6B1",
    font_size_primary: "24px",
    font_size_mobile_primary: "12px",
  },
  background: {
    main: "#202020",
    gradient:
      "linear-gradient(-45deg, #D7B563 0%, #DCBD53 9.38%, #B2843C 26.04%, #F4E478 58.85%, #BB913D 78.13%, #CDA865 90.63%, #D7B563 100%)",
    second: "#202020",
    sidebar: "#202020",
    header: "#202020",
    mobileHeader: "#363636",
    secondHeader: "#000",
  },
  primary: {
    hover: "#e3bd77",
    main: "#E7B556",
    light: "#E7B556",
    black: "#000000",
  },
  secondary: {
    main: "#363636",
    focused: "#5F5F5F",
    disabled: "#A1A1A1",
  },
  text: {
    white: "#FFFFFF",
    black: "#000000",
    listMenu: "#E7B556",
  },
  success: {
    main: "#C89D4B",
  },
  error: {
    main: "#CB2011",
  },
  warning: {
    main: "#202020",
  },

  main_card: {
    background: "#000000",
  },
  icons: {
    input: "#FFFFFF",
  },
};

const izibetTheme: DefaultTheme = theme;

export default izibetTheme;
