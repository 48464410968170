import { useRef } from "react";
import { toast, ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { ReactComponent as ExitIcon } from "../assets/images/cross_icon.svg";
import IconButton from "./material-kit/IconButton";

const CloseButton = ({ closeToast }: any) => (
  <IconButton id={"exit_toast_icon"} onClick={closeToast}>
    <Icon />
  </IconButton>
);
const PrettyToast = () => {
  const ref = useRef<HTMLDivElement>(null);

  if (
    ref.current?.children[0]?.children?.length &&
    ref.current?.children[0]?.children?.length > 0
  ) {
    toast.dismiss();
  }

  return (
    <CustomToast>
      <ToastContainer
        ref={ref}
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        limit={1}
        pauseOnHover
        theme="colored"
        closeButton={CloseButton}
      />
    </CustomToast>
  );
};

export default PrettyToast;

const CustomToast = styled.div<ToastContainerProps>`
  position: fixed;
  z-index: 10000000;
  text-transform: uppercase;
  margin: 0;

  .Toastify__toast-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .Toastify__toast {
    margin: 0;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: ${({ theme }) => theme.error.main};
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: ${({ theme }) => theme.success.main};
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: ${({ theme }) => theme.warning.main};
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background-color: ${({ theme }) => theme.warning.main};
  }

  .Toastify__progress-bar {
    height: 100%;
    opacity: 0.3 !important;
    transform-origin: right;
  }

  .Toastify__toast-icon {
    display: none;
  }
`;

const Icon = styled(ExitIcon)`
  padding: 8px 0px;

  path {
    stroke: ${({ theme }) => theme.text.white};
  }
`;
