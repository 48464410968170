import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUserState } from "redux/types/userTypes";
import { userAPI } from "../api/userApi";
import settings from "settings";
import axios from "axios";
import { BASE_URL } from "../api/commonApi";
import { putStorage } from "hooks/useStorage";

const initialState: IUserState = {
  isOpenedPopupModal: false,
  popups: {
    count: 0,
    rows: [],
  },
  unviewedPopups: {
    count: 0,
    rows: [],
  },
  user: null,
  verification: {
    status: "none",
    message: "",
    rejectReason: "",
  },
  lastCashback: null,
};

export const createVerificationImages = createAsyncThunk(
  "adminApi/createVerificationImages",
  async (obj: FormData) => {
    const response = await axios({
      url: `${BASE_URL}/api/verifications`,
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: obj,
    })
      .then((pld) => {
        return {
          type: "adminApi/createVerificationImages",
          method: "POST",
          status: pld.status,
        };
      })
      .catch((pld) => {
        return {
          type: "adminApi/createVerificationImages",
          method: "POST",
          status: "rejected",
          data: { message: pld?.response?.data?.message },
        };
      });
    return response;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetAdmin(state) {
      state.user = null;
    },
    updateBalance(state, { payload }) {
      if (state.user) {
        state.user.balance = payload;
      }
    },
    openPopupModal(state, { payload }) {
      state.isOpenedPopupModal = payload;
    },
    resetPopups(state) {
      state.popups = {
        rows: [],
        count: 0,
      };
    },
    updateLastCashback(state, {payload}) {
      state.lastCashback = payload.id && payload.amount && payload.amount > 0 ? payload : null
    }
  },
  extraReducers: (builder) => {
    builder
      // matchFulfilled
      .addCase(createVerificationImages.fulfilled, (state: any, action) => {
        state.verification.status = "created";
      })
      .addMatcher(
        userAPI.endpoints.loginMe.matchFulfilled,
        (state, { payload }) => {
          window.location.reload();
          state.user = payload;
          putStorage("ACCESS", settings.SETTINGS.ACCESS, { silent: true });
        }
      )
      .addMatcher(
        userAPI.endpoints.getUserData.matchFulfilled,
        (state, { payload }) => {
          state.isOpenedPopupModal = true;
          state.user = payload;
          putStorage("ACCESS", settings.SETTINGS.ACCESS, { silent: true });
        }
      )
      .addMatcher(
        userAPI.endpoints.getUserData.matchRejected,
        (state, { payload }) => {
          localStorage.removeItem("popupsWereOpened");
        }
      )
      .addMatcher(
        userAPI.endpoints.getUserBalance.matchFulfilled,
        (state, { payload }) => {
          if (state.user) state.user.balance = payload.data.balance;
        }
      )
      .addMatcher(userAPI.endpoints.logoutMe.matchFulfilled, (state) => {
        state.user = null;
      })
      .addMatcher(
        userAPI.endpoints.updateUserData.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.getFullUserData.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.createVerification.matchFulfilled,
        (state, { payload }) => {
          state.verification.status = payload.status;
        }
      )
      .addMatcher(
        userAPI.endpoints.getVerificationStatus.matchFulfilled,
        (state, { payload }) => {
          state.verification.status = payload.status || "";
          state.verification.message = payload.message || "";
          state.verification.rejectReason = payload.rejectReason || "";
        }
      )
      .addMatcher(
        userAPI.endpoints.getPopups.matchFulfilled,
        (state, { payload }) => {
          state.popups.rows = [...state.popups.rows, ...payload.rows];
          state.popups.count = payload.count;
        }
      )
      .addMatcher(
        userAPI.endpoints.hasUnviewedPopups.matchFulfilled,
        (state, { payload }) => {
          state.unviewedPopups = payload;
        }
      )
      .addMatcher(
        userAPI.endpoints.checkPopupViewed.matchFulfilled,
        (state, { payload }) => {
          const currentMessagePopup = state.popups.rows.find((p) => {
            return p.id === payload.id;
          });
          if (currentMessagePopup) {
            currentMessagePopup.isViewed = true;
          }
          const authPopup = state.unviewedPopups.rows.find((p) => {
            return p.id === payload.id;
          });
          if (authPopup) {
            // TODO: по идее нужно фильтровать список непросмотренных сообщений
            // state.unviewedPopups.rows = state.unviewedPopups.rows.filter(
            //   (p) => p.id !== authPopup.id
            // );
            authPopup.isViewed = true;
            state.unviewedPopups.count -= 1;
          }
        }
      )
      .addMatcher(
        userAPI.endpoints.getLastCashback.matchFulfilled,
        (state, { payload }) => {
          state.lastCashback = payload.id && payload.amount && payload.amount > 0 ? payload : null;
        }
      )
      .addMatcher(userAPI.endpoints.updateLastCashback.matchFulfilled, (state) => {
        state.lastCashback = null;
      })
      .addDefaultCase((state, action) => {});
  },
});

export const { resetAdmin, updateBalance, resetPopups, openPopupModal, updateLastCashback } =
  userSlice.actions;

export default userSlice.reducer;
