import { CSSProperties } from "react";
import styled, { css } from "styled-components";

interface IExternalContentProps {
  content: string;
  id?: string;
  style?: CSSProperties;
  className?: string;
}

const projectName = process.env.REACT_APP_INSTANCE;

export const ExternalContent = ({
  content,
  className,
  id,
  style,
}: IExternalContentProps) => (
  <Wrapper
    id={id}
    dangerouslySetInnerHTML={{ __html: content }}
    style={style}
  />
);

const Wrapper = styled.div`
  .external_content {
    width: 100%;
    overflow: auto;
    grid-area: 2/2;
    text-overflow: ellipsis;
    hyphens: auto;
    hyphenate-limit-chars: 6 3 2;
    -webkit-hyphenate-limit-before: 3;
    -webkit-hyphenate-limit-after: 2;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;

    ::-webkit-scrollbar {
      width: 0.125rem;
      height: 0.125rem;
    }

    * {
      word-break: break-word;
    }

    p {
      margin: 1rem 0;
      padding: 0;

      @media (max-width: $device-tablet) {
        margin: 0.5rem 0;
      }
    }

    @media (max-width: $device-tablet) {
      font-size: 0.875rem;
    }
  }
`;
