import { device } from "constants/deviceSizes";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import styled, { CSSProperties } from "styled-components";

interface NavbarItemProps {
  iconPath?: string;
  activeIconPath?: string;
  isFrench?: boolean;
  isActive?: boolean;
  titleKey?: string;
  onClick?: () => void;
  style?: CSSProperties;
  imageStyle?: CSSProperties;
}

export const NavbarItem = (props: NavbarItemProps) => {
  const {
    iconPath,
    activeIconPath,
    isFrench,
    titleKey,
    onClick,
    isActive,
    style,
    imageStyle,
  } = props;

  const { t, i18n } = useTranslation();
  const isRight = i18n.dir() === "rtl";

  const icon = iconPath ? require(`../../../${iconPath}`) : undefined;
  const activeIcon = activeIconPath
    ? require(`../../../${activeIconPath}`)
    : undefined;

  return (
    <CardWrapper onClick={onClick} isRight={isRight} style={style}>
      <Card isFrench={isFrench}>
        <img
          src={isActive && activeIcon ? activeIcon : icon}
          style={imageStyle}
        />
        <span>{t(`common.footer.${titleKey}`)}</span>
      </Card>
    </CardWrapper>
  );
};

const Card = styled.div<{ isFrench?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  img {
    max-width: 60px;
    position: center;
    flex-shrink: 1;
  }

  span {
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.white};
    margin-bottom: 4px;
  }

  @media (${device.tabletXS}) {
    justify-content: ${({ isFrench }) =>
      isFrench ? "space-between" : "flex-end"};

    img {
      max-width: ${({ isFrench }) => (isFrench ? "50px" : "60px")};
    }

    span {
      padding: ${({ isFrench }) => (isFrench ? "0 5px" : "0")};
      line-height: ${({ isFrench }) => (isFrench ? "10px" : "auto")};
    }
  }
`;

const CardWrapper = styled.div<{ isRight?: boolean }>`
  display: flex;
  justify-content: center;
  &:first-child {
    ${({ isRight }) => (isRight ? "padding-right" : "padding-left")}: 20px;
  }
  &:last-child {
    ${({ isRight }) => (isRight ? "padding-left" : "padding-right")}: 20px;
  }
`;
