import React from "react";
import "./Loader.css";

interface ILoader {
  width?: number;
}

const project = process.env.REACT_APP_INSTANCE;

const Loader = (props: ILoader) => {
  console.log(project === "megawin");
  const { width } = props;
  return (
    <div id="wrapper">
      <div className="profile-main-loader">
        <div className="loader" style={{ width: width, height: width }}>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle
              className="loader-path"
              data-project={project}
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke={project === "megawin" ? "#B98DFF" : "#E7B556"}
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loader;
