import styled, { css } from "styled-components";
import ProfileModal from "../modals/ProfileModal";
import routingLinks from "../../constants/routingLinks";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface IProfileWrapper {
  title: string;
  count?: number;
  childrenInfo: JSX.Element;
  childrenResult?: JSX.Element | null;
}

const projectName = process.env.REACT_APP_INSTANCE;

const DesktopWrapper = (props: IProfileWrapper) => {
  const { title, childrenInfo, childrenResult, count } = props;
  const route = routingLinks.find((item) => item.key === title);
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Wrapper isProfileMessages={route?.key === "PROFILE_MESSAGES"}>
        <Navigation isOpen={true} onClose={() => undefined} isMobile={false} />
        <InfoBlock isMessages={location.pathname === "/profile/messages"}>
          <Image isProfileMessages={route?.key === "PROFILE_MESSAGES"}>
            {route?.iconJsx}
          </Image>
          {childrenInfo}
        </InfoBlock>
        {childrenResult === null ? (
          childrenResult
        ) : childrenResult !== undefined ? (
          <ResultBlock transparent={count !== 0} id={`scrollableDivOperations`}>
            {count ? childrenResult : t(`common.info.choose_time_interval`)}
          </ResultBlock>
        ) : (
          <div />
        )}
      </Wrapper>
    </>
  );
};

export default DesktopWrapper;

const Navigation = styled(ProfileModal)`
  z-index: 1;
`;

const Wrapper = styled.div<{ isProfileMessages: boolean }>`
  min-height: 700px;
  margin: 16px auto;
  max-width: 1400px;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.text.black};
  display: grid;
  grid-template-columns: 318px 1fr 1fr;
  grid-template-rows: min-content;
  grid-column-gap: 32px;
  box-sizing: border-box;
  padding: 44px 54px 54px;
  border-radius: 20px;

  ${({ isProfileMessages }) =>
    isProfileMessages &&
    css`
      grid-template-columns: 318px 1fr;
    `}
`;

const InfoBlock = styled.div<{ isMessages: boolean }>`
  display: grid;
  justify-items: center;
  grid-auto-rows: min-content;
  grid-row-gap: 10px;
`;

const ResultBlock = styled.div<{ transparent: boolean }>`
  background-color: transparent;
  height: 602px;
  border-radius: 0;
  display: grid;
  grid-row-gap: 15px;
  overflow: auto;
  text-transform: uppercase;
  min-width: 300px;
  width: 100%;
  ${({ transparent }) =>
    !transparent &&
    css`
      border-radius: 20px;
      justify-items: center;
      align-items: center;
      background-color: ${({ theme }) => theme.background.main};
      font-size: 24px;
      color: ${({ theme }) => theme.secondary.focused};
    `}
`;

const Image = styled.div<{ isProfileMessages: boolean }>`
  margin-top: 94px;
  width: 160px;
  height: 160px;

  ${({ isProfileMessages }) =>
    isProfileMessages &&
    css`
      margin-top: 0;
    `}

  & > * {
    margin-inline: auto;
    display: block;
    
    ${projectName !== "megawin" &&
    css`
      width: 100%;
    `}
    height: 100%;
  }
`;