import format from "date-fns/format";
import useTime from "../hooks/useTime";
import styled from "styled-components";
import { device } from "../constants/deviceSizes";

const project = process.env.REACT_APP_INSTANCE;

const CurrentDate = () => {
  const time = useTime();
  return (
    <DateContainer>
      {project !== "mybet" && project !== "megawin" && (
        <Date>{format(time, "HH:mm:ss")}</Date>
      )}
      <Date>{format(time, "dd.MM.yyyy")}</Date>
    </DateContainer>
  );
};

export default CurrentDate;

const Date = styled.div`
  color: ${({ theme }) => theme.secondary.disabled};
`;
const DateContainer = styled.div`
  gap: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${device.laptopM}) {
    gap: 20px;
  }
`;
